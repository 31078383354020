import axios from 'axios'
import store from '@/store'
import { API_ERROR_SUBTYPES } from '@/constants'

const API_BASE = process.env.VUE_APP_API
const SETTINGS_URL = `${API_BASE}/pei/schools`

function getPeiCustSettings(schoolCode) {
  const url = `${SETTINGS_URL}/${schoolCode}/customisation`
  const config = {
    headers: {
      schoolcode: schoolCode
    }
  }
  return axios
    .get(url, config)
    .then((response) => response)
    .catch((error) => {
      if (error && error.response) {
        const statusCode = error.response.status
        if (statusCode !== 403) {
          store.dispatch('setAPIError', {
            error,
            fallbackCode: API_ERROR_SUBTYPES.getSchoolCustomisationSettings
          })
          throw error
        }
      }
    })
}

function savePeiCustSettings(schoolCode, payload) {
  store.commit('resetError')
  const url = `${SETTINGS_URL}/${schoolCode}/customisation`
  const config = {
    headers: {
      schoolcode: schoolCode
    }
  }
  return axios.put(url, payload, config).catch((error) => {
    store.dispatch('setAPIError', {
      error,
      fallbackCode: API_ERROR_SUBTYPES.saveSchoolCustomisationSettings
    })
    throw error
  })
}

export default { getPeiCustSettings, savePeiCustSettings }
